<template>
  <div>
    <div v-html="content.content"></div>
  </div>
</template>

<script>
import {textContentsClient} from '../components/api/clients.context'
import {Component, Vue} from "vue-property-decorator";

@Component
export default class impress extends Vue {
  content = null;

  async mounted() {
    this.content = await textContentsClient.textContentByKey("imprint");

  }
}
</script>

<style scoped>

</style>