import { render, staticRenderFns } from "./impress.vue?vue&type=template&id=1c43839a&scoped=true&"
import script from "./impress.vue?vue&type=script&lang=js&"
export * from "./impress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c43839a",
  null
  
)

export default component.exports